<template>
	<div class="main">
		
		<div class="tit"><span>收藏列表</span></div>
		<div v-loading="loading">
			<!-- 嵌入IP列表 --> 
			<Ipitems v-bind:ipsData="ipsData" ></Ipitems>
		</div>
		
	</div>
</template>
<script type="text/javascript"> 
	import Ipitems from '@/views/ipdetail/ipItems.vue' 

	export default {
		data(){
			return {
				loading:false,  
				ipsData:{
					type:2,
					data:{},
					page:{
						page:1,
						pageSize:10,
					}
				}
			}
		},
		created(){
			console.log(1)  
		},
		components:{
			Ipitems, 
		},
		methods:{ 
		}
	}
</script>
<style type="text/css" scoped="">
	.main{flex: 1;background: #FFFFFF;border-radius: 4px;padding: 20px 25px;}
	:deep(.listBox) {padding-bottom: 120px;width: 100%;}
	:deep(.listBox .listTitle) {width: 100%;}
	:deep(.listBox .listItems) {width: 100%;}
	:deep(.listBox .listTitle .name){width: 310px;}
	:deep(.listBox .listTitle .lables){width: 340px;}
	:deep(.ipListItem .ipName){width: 310px;}
	:deep(.ipListItem .lables){width: 340px;}
	:deep(.listBox .listTitle .index){width: 120px;}
	:deep(.ipListItem .index){width: 120px;}
	:deep(.listBox .listTitle .price){width: 150px;}
	:deep(.ipListItem .price){width: 150px;}
	:deep(.listBox .listTitle .operas){width: 120px;}
	:deep(.ipListItem .operas){width: 120px;}
</style>